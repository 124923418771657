<template>
    <div class="o-row-full c-legal-footer">
        <div class="o-extra-small--12">
            <ul class="c-legal-footer__ul">
                <li
                    v-for="(link, index) in legalLinks"
                    :key="`social-${index}`"
                    class="c-legal-footer__li"
                >
                    <BaseLink
                        :to="getLinkUrl(link)"
                        :target="link.target"
                        :class="{
                            'o-text--link': true,
                            'o-text--caption': true,
                            'is-active': $route.path === link.targetUrl || $route.path === link.slug
                        }"
                        @click="trackFooterClicked(link)"
                    >
                        {{ link.displayName }}
                    </BaseLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { LINK_CLICKED } from '~coreModules/core/js/global-event-constants';
import { SELECT_LINK_TYPES } from '~coreModules/analytics/js/analytics-constants';

defineProps({
    legalLinks: {
        type: Array,
        default: () => [],
    },
});

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

function getLinkUrl(link) {
    return link.targetUrl || link.slug;
}

function trackFooterClicked(link) {
    const destination = getLinkUrl(link);

    trackGlobalEvent({
        type: LINK_CLICKED,
        data: {
            type: SELECT_LINK_TYPES.FOOTER,
            destination,
        },
    });
}
</script>

<style lang="scss">
    .c-legal-footer {
        background-color: $nu-white;
        flex-wrap: wrap;

        &__ul {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin: $nu-spacer-1 0;
        }

        &__li {
            display: block;
            position: relative;
            margin: 0 $nu-spacer-2;

            &:not(:last-of-type)::after {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                right: -($nu-spacer-2);
                margin: auto;
                width: 1px;
                height: 70%;
                background-color: $nu-gray--medium;
            }
        }
    }
</style>
