/**
 * Gets the site navigation
 * @param {Object} r15Svc  - rest client to make r15 api calls
 * @param {Object} apiUrls - api urls from the runtimeConfig
 *
 * @return {Promise} service request
 */
export function getNavigation(r15Svc, { apiUrls }) {
    return r15Svc.get(`${apiUrls.navigationService}?ancestorSlugs=shopping-root,help-root,marketing-root,footer-root`);
}

export default {
    getNavigation,
};
