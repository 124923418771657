export const ACCORDION_OPENED = 'ACCORDION_OPENED';
export const CAROUSEL_SCROLL = 'CAROUSEL_SCROLL';
export const CATALOG_FILTER_UPDATED = 'CATALOG_FILTER_UPDATED';
export const CATALOG_SORT_UPDATED = 'CATALOG_SORT_UPDATED';
export const CHECKOUT_START = 'CHECKOUT_START';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CONTENTFUL_CONTENT_CLICKED = 'CONTENTFUL_CONTENT_CLICKED';
export const CONTENT_CARD_CLICKED = 'CONTENT_CARD_CLICKED';
export const CONTENT_CARD_SCROLL = 'CONTENT_CARD_SCROLL';
export const CTA_BUTTON_CLICKED = 'CTA_BUTTON_CLICKED';
export const EMAIL_SUBSCRIBE = 'EMAIL_SUBSCRIBE';
export const FEEDBACK_COMPLETED = 'FEEDBACK_COMPLETED';
export const FEEDBACK_STARTED = 'FEEDBACK_STARTED';
export const IN_GRID_CONTENT_CLICKED = 'IN_GRID_CONTENT_CLICKED';
export const LINK_CLICKED = 'LINK_CLICKED';
export const LOGIN_COMPLETED = 'LOGIN_COMPLETED';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGOUT = 'LOGOUT';
export const MARKETING_SMS_SUBSCRIBED = 'MARKETING_SMS_SUBSCRIBED';
export const NAVIGATION_CLICKED = 'NAVIGATION_CLICKED';
export const NOTIFICATION_CLICKED = 'NOTIFICATION_CLICKED';
export const NOTIFICATION_DISMISSED = 'NOTIFICATION_DISMISED';
export const NOTIFICATION_VIEWED = 'NOTIFICATION_VIEWED';
export const OPERATIONAL_SMS_SUBSCRIBED = 'OPERATIONAL_SMS_SUBSCRIBED';
export const PAGEVIEW = 'PAGEVIEW';
export const PAGINATION_CLICK = 'PAGINATION_CLICK';
export const PRODUCT_CLICK = 'PRODUCT_CLICK';
export const PRODUCT_SAVED_CHANGE = 'PRODUCT_SAVED_CHANGE';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PURCHASE_SUCCESS = 'PURCHASE_SUCCESS';
export const SEARCH = 'SEARCH';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const SIGN_UP_STARTED = 'SIGN_UP_STARTED';
export const SIGN_UP_COMPLETED = 'SIGN_UP_COMPLETED';
export const SOCIAL_SHARE = 'SOCIAL_SHARE';
export const VIEW_ALL_CLICK = 'VIEW_ALL_CLICK';
export const VIEW_CONTENT_CARD = 'VIEW_CONTENT_CARD';
export const VIEW_PRODUCT = 'VIEW_PRODUCT';
export const VIEW_PRODUCT_LIST = 'VIEW_PRODUCT_LIST';
