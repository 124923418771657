export const NUU_HEADER_AUTH_CODE_BYPASS = 'x-urbn-auth-code-bypass';
export const NUU_HEADER_CLIENT_ID = 'X-Nuuly-ClientId';
export const URBN_HEADER_CLIENT_ID = 'x-urbn-clientId';
export const NUU_HEADER_CHANNEL = 'x-urbn-channel';
export const NUU_HEADER_PROFILE_ID = 'x-urbn-profileId';
export const NUU_HEADER_TRACKING_OPT_IN = 'x-urbn-trackingOptIn';
export const NUU_HEADER_CURRENCY = 'x-urbn-currency';
export const NUU_HEADER_TRACE_ID = 'x-urbn-traceid';
export const NUU_HEADER_REGION = 'x-urbn-region';
export const NUU_HEADER_COUNTRY = 'x-urbn-country';
export const NUU_HEADER_QUERY_VERSION = 'X-URBN-QUERY-VERSION';
export const NUU_HEADER_LOCATION_ID = 'x-urbn-location-id';
export const NUU_HEADER_LOCATION_OVERRIDE = 'x-urbn-location-override';
export const NUU_HEADER_VENDOR_CLICK_ID = 'x-urbn-vendor-click-id';
export const NUU_HEADER_RESPONSE_ID = 'x-urbn-responseId';

export const NUU_COMMON_APPLICATION_CHANNEL = 'common-web-cx';
export const NUU_RENTAL_APPLICATION_CHANNEL = 'rental-web-cx';
export const NUU_THRIFT_APPLICATION_CHANNEL = 'thrift-web-cx';

export const NUU_QUERY_REFERER = 'refr';

export const LOGIN_STATUSES = {
    REGISTERED: 'REGISTERED',
    GUEST: 'GUEST',
};

export const NUULY_BUSINESS_TYPES = {
    rental: 'RENTAL',
    thrift: 'THRIFT',
    common: 'COMMON',
};

export const URBN_BRANDS = ['Urban Outfitters', 'Free People', 'Anthropologie'];

// Image presets for Adobe Scene7
export const GLOBAL_IMAGE_PRESETS = {
    browse: {
        regular: '$browse-regular$',
        large: '$browse-large$',
    },
    product: {
        regular: '$pdp-regular$',
        zoom: '$pdp-zoom$',
        thumb: '$pdp-thumb$',
        small: '$pdp-small$',
        ogImage: '$og-image$',
    },
    productTray: '$tray-regular$',
    ugc: {
        regular: '$ugc-regular$',
        square: '$ugc-square$',
    },
};

export const DEBOUNCE_DELAY = 150;
export const KEYSTROKE_DEBOUNCE_DELAY = 300;
export const SCROLL_IN_ANIMATION_DELAY = 1000;

export const NOTIFICATION_DISMISS_DELAY = {
    SHORT: 1500,
    MEDIUM: 3000,
    LONG: 5000,
};

export const DIRECTION_PREVIOUS = 'DIRECTION_PREVIOUS';
export const DIRECTION_NEXT = 'DIRECTION_NEXT';

export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const FORWARD = 'forward';
export const BACKWARD = 'backward';

export const FOCUSABLE_SELECTORS =
    'a[href], area[href], input:not([disabled]), ' +
    'select:not([disabled]), textarea:not([disabled]), ' +
    'button:not([disabled]), iframe, object, embed, ' +
    '*[tabindex], *[contenteditable]';

export const BUTTON_STYLE_TYPES = {
    INLINE: 'inline',
    INLINE_TILE: 'inline-tile',
    MENU: 'menu',
    INLINE_TILE_NOWRAP: 'inline-nowrap',
    TAB: 'tab',
    GRID: 'grid',
};

export const KEYCODES = {
    tab: 9,
    return: 13,
    escape: 27,
    space: 32,
    arrowRight: 39,
};

export const SELECT_STYLE_TYPES = {
    DEFAULT: 'default',
    SORT: 'sort',
};

// eslint-disable-next-line max-len
export const US_STATES = [{ label: 'Alabama', value: 'AL' }, { label: 'Alaska', value: 'AK' }, { label: 'Arizona', value: 'AZ' }, { label: 'Arkansas', value: 'AR' }, { label: 'California', value: 'CA' }, { label: 'Colorado', value: 'CO' }, { label: 'Connecticut', value: 'CT' }, { label: 'Delaware', value: 'DE' }, { label: 'District Of Columbia', value: 'DC' }, { label: 'Florida', value: 'FL' }, { label: 'Georgia', value: 'GA' }, { label: 'Hawaii', value: 'HI' }, { label: 'Idaho', value: 'ID' }, { label: 'Illinois', value: 'IL' }, { label: 'Indiana', value: 'IN' }, { label: 'Iowa', value: 'IA' }, { label: 'Kansas', value: 'KS' }, { label: 'Kentucky', value: 'KY' }, { label: 'Louisiana', value: 'LA' }, { label: 'Maine', value: 'ME' }, { label: 'Maryland', value: 'MD' }, { label: 'Massachusetts', value: 'MA' }, { label: 'Michigan', value: 'MI' }, { label: 'Minnesota', value: 'MN' }, { label: 'Mississippi', value: 'MS' }, { label: 'Missouri', value: 'MO' }, { label: 'Montana', value: 'MT' }, { label: 'Nebraska', value: 'NE' }, { label: 'Nevada', value: 'NV' }, { label: 'New Hampshire', value: 'NH' }, { label: 'New Jersey', value: 'NJ' }, { label: 'New Mexico', value: 'NM' }, { label: 'New York', value: 'NY' }, { label: 'North Carolina', value: 'NC' }, { label: 'North Dakota', value: 'ND' }, { label: 'Ohio', value: 'OH' }, { label: 'Oklahoma', value: 'OK' }, { label: 'Oregon', value: 'OR' }, { label: 'Pennsylvania', value: 'PA' }, { label: 'Rhode Island', value: 'RI' }, { label: 'South Carolina', value: 'SC' }, { label: 'South Dakota', value: 'SD' }, { label: 'Tennessee', value: 'TN' }, { label: 'Texas', value: 'TX' }, { label: 'Utah', value: 'UT' }, { label: 'Vermont', value: 'VT' }, { label: 'Virginia', value: 'VA' }, { label: 'Washington', value: 'WA' }, { label: 'West Virginia', value: 'WV' }, { label: 'Wisconsin', value: 'WI' }, { label: 'Wyoming', value: 'WY' }];

export const CREDIT_CARD_TYPES = {
    VISA: 'Visa',
    MASTERCARD: 'MasterCard',
    DISCOVER: 'Discover',
    AMERICAN_EXPRESS: 'American Express',
    NU_GIFT_CARD: 'Nuuly Gift Card',
};

export const CREDIT_CARD_IMAGE_MAP = {
    [CREDIT_CARD_TYPES.VISA]: () => import('~coreImages/billing__card--visa.png'),
    [CREDIT_CARD_TYPES.MASTERCARD]: () => import('~coreImages/billing__card--mastercard.png'),
    [CREDIT_CARD_TYPES.DISCOVER]: () => import('~coreImages/billing__card--discover.png'),
    [CREDIT_CARD_TYPES.AMERICAN_EXPRESS]: () => import('~coreImages/billing__card--amex.png'),
    [CREDIT_CARD_TYPES.NU_GIFT_CARD]: () => import('~coreImages/billing__card-nuuly-gift-card.png'),
};

export const CREDIT_CARD_SIZES = {
    SMALL: 'small',
    LARGE: 'large',
    XLARGE: 'xlarge',
};

export const PAYMENT_INSTITUTIONS = {
    VISA: 'visa',
    MASTERCARD: 'mastercard',
    DISCOVER: 'discover',
    AMERICAN_EXPRESS: 'amex',
    NUULY_CREDIT: 'nuuly_credit',
};

export const PAYMENT_INSTITUTIONS_MAP = {
    [PAYMENT_INSTITUTIONS.VISA]: CREDIT_CARD_TYPES.VISA,
    [PAYMENT_INSTITUTIONS.MASTERCARD]: CREDIT_CARD_TYPES.MASTERCARD,
    [PAYMENT_INSTITUTIONS.DISCOVER]: CREDIT_CARD_TYPES.DISCOVER,
    [PAYMENT_INSTITUTIONS.AMERICAN_EXPRESS]: CREDIT_CARD_TYPES.AMERICAN_EXPRESS,
};

export const APPLICATION_CHANNELS_MAP = {
    [NUULY_BUSINESS_TYPES.common]: NUU_COMMON_APPLICATION_CHANNEL,
    [NUULY_BUSINESS_TYPES.rental]: NUU_RENTAL_APPLICATION_CHANNEL,
    [NUULY_BUSINESS_TYPES.thrift]: NUU_THRIFT_APPLICATION_CHANNEL,
};

export const MAX_AGE_IN_YEARS = 100;
export const MIN_AGE_IN_YEARS = 13;

export const TTL_ONE_DAY = 86400000; // 1 day;

export const PRODUCT_TRAY_HEADER_LINK_PLACEMENTS = {
    UNDER_CONTENT: 'under_content',
    RIGHT: 'right',
};
