<template>
    <LazyImage
        v-if="isLazyImage"
        :imgSrc="imageUrl"
        :title="safeTitle"
        :shouldDisplayImage="shouldDisplayImage"
        :fadeInAfterLoaded="fadeInAfterLoaded"
        :class="{ 'c-product-image--with-outline': showFocusOutline }"
        @load-error="handleLoadError"
    />
    <img
        v-else
        ref="productImage"
        :src="imageUrl"
        :alt="safeTitle"
        :class="{ 'c-product-image--with-outline': showFocusOutline }"
        @error="handleLoadError"
    >
</template>

<script>
import { doesBrowserSupportCssHas } from '~coreModules/browser/js/browser-utils';

import LazyImage from '~coreModules/core/components/ui/LazyImage.vue';
import ErrorGraphic from '~coreImages/illustration__error-graphic--7.png';

export default {
    name: 'ProductImage',
    components: {
        LazyImage,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: null,
        },
        choiceId: {
            type: String,
            default: null,
        },
        imagePreset: {
            type: String,
            default: null,
        },
        isLazyImage: {
            type: Boolean,
            default: false,
        },
        shouldDisplayImage: {
            type: Boolean,
            default: false,
        },
        fadeInAfterLoaded: {
            type: Boolean,
            default: false,
        },
        fallbackSrc: {
            type: String,
            default: '',
        },
        allowFocusOutline: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadFailed: false,
        };
    },
    computed: {
        imageUrl() {
            const url = this.src;

            if (this.loadFailed) {
                return this.fallbackSrc || ErrorGraphic;
            }

            if (this.imagePreset) {
                return `${url}?${this.imagePreset}`;
            }

            return url;
        },
        showFocusOutline() {
            return this.allowFocusOutline && !doesBrowserSupportCssHas();
        },
        safeTitle() {
            return this.title || '';
        },
    },
    watch: {
        src: {
            handler(newImage, oldImage) {
                if (newImage !== oldImage) {
                    this.loadFailed = false;
                }
            },
        },
    },
    methods: {
        handleLoadError() {
            this.loadFailed = true;
        },
    },
};
</script>

<style lang="scss">
    .c-product-image--with-outline {
        .focus-visible:focus[data-focus-visible-added] & {
            outline: $focus-outline;
            outline-offset: -4px;
            transition: unset;
        }
    }
</style>
