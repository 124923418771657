// This config module is the main location for application-level configuration.
// Because this module gathers a lot of it's information from environment variables
// and information only known on the server, we export it and render it into a
// meta tag in the initial HTML render and when accessing this module client
// side, we parse it from the meta tag.  This allows us to avoid any node
// polyfills in our client code and avoids needing to specify EnvironmentPlugin
// values for he vast majority of our environment variables in our webpack
// builds

const { merge } = require('webpack-merge');

function getRuntimeConfig(appConfig) {
    let runtimeConfig;

    // Server side - Generate from environment vars
    if (process.env.VUE_ENV === 'server') {

        const reportURI = process.env.NUU_CSP_REPORT_URI ?
            `${process.env.NUU_API_PATH}${process.env.NUU_CSP_REPORT_URI}` : null;

        runtimeConfig = {

            apiDomains: {
                // NUU_CLIENT_API_PATH is set locally when running with docker-compose to remove the port from api path
                rest: process.env.NUU_CLIENT_API_PATH || process.env.NUU_API_PATH,
                restServer: process.env.NUU_API_PATH,
            },

            // Urls for API calls
            apiUrls: {
                authService: process.env.NUU_AUTH_SERVICE_API_URL,
                chatService: process.env.NUU_CHAT_SERVICE_API_URL,
                navigationService: process.env.NUU_NAVIGATION_SERVICE_API_URL,
                profileService: process.env.NUU_PROFILE_SERVICE_API_URL,
                reviewService: process.env.NUU_REVIEW_SERVICE_API_URL,
                socialService: process.env.NUU_SOCIAL_SERVICE_API_URL,
            },

            authCookieName: process.env.NUU_COOKIE_AUTH_TOKEN,

            // Braze Public API key
            brazePublicApiKey: process.env.NUU_BRAZE_PUBLIC_API_KEY,

            // Contentful space id and access token for the current brand and environment
            contentful: {
                apiUrl: `${process.env.NUU_CONTENTFUL_BASE_URL}${process.env.NUU_CONTENTFUL_SPACE_ID}`,
                spaceId: process.env.NUU_CONTENTFUL_SPACE_ID,
                accessToken: process.env.NUU_CONTENTFUL_ACCESS_TOKEN,
                environment: process.env.NUU_CONTENTFUL_ENVIRONMENT,
            },

            // Configs for building Content Security Policy
            csp: {
                reportURI,
                reportingEnabled: process.env.NUU_CSP_REPORT_ENABLED === 'true',
            },

            // Default country code to use if we don't get a valid Akamai header
            defaultCountry: 'US',

            // Default currency to use if we don't have a valid option
            defaultCurrency: 'USD',

            // Default currency to use if we don't have a valid option
            defaultLanguage: 'en-US',

            // Node environment
            environment: process.env.APP_ENV,

            // Facebook App Id
            facebookAppId: process.env.NUU_FACEBOOK_APP_ID,

            // To be filled in from entry-server based on request
            features: null,

            // Log level used by the server and the client
            logLevel: process.env.NUU_LOG_LEVEL || 'debug',

            newRelic: {
                accountId: process.env.NUU_NEW_RELIC_ACCOUNT_ID,
                agentId: process.env.NUU_NEW_RELIC_AGENT_ID,
                applicationId: process.env.NUU_NEW_RELIC_APPLICATION_ID,
                licenseKey: process.env.NUU_NEW_RELIC_LICENSE_KEY,
                trustKey: process.env.NUU_NEW_RELIC_TRUST_KEY,
            },

            // Configuration for the Node Express server
            port: process.env.NUU_PORT || process.env.PORT || 8080,

            testing: {
                useAuthCodeBypass: (process.env.NUU_USE_AUTH_CODE_BYPASS === 'true') || false,
            },

            // Version of the app
            version: process.env.NUU_VERSION || '0.0.0',

            // Currency display configuration passed to vue-i18n.
            numberFormats: {
                'en-US': {
                    // Transactional
                    USD: { style: 'currency', currency: 'USD' },
                    currencyNoCents: {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    },
                    decimal: { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 },
                },
            },

            // Date display configuration passed to vue-i18n.
            dateTimeFormats: {
                'en-US': {
                    short: {
                        year: 'numeric', month: 'short', day: 'numeric',
                    },
                    numeric: {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                    },
                    yearless: {
                        month: 'long', day: 'numeric',
                    },
                    yearlessNumeric: {
                        month: 'numeric', day: '2-digit',
                    },
                    yearlessShort: {
                        month: 'short', day: 'numeric',
                    },
                    year: {
                        year: 'numeric',
                    },
                    simple: {
                        year: '2-digit', month: 'numeric', day: 'numeric',
                    },
                    fullDate: {
                        weekday: 'long', month: 'short', day: 'numeric',
                    },
                    fullMonth: {
                        year: 'numeric', month: 'long', day: 'numeric',
                    },
                    dayless: {
                        month: 'long', year: 'numeric',
                    },
                    twoDigits: {
                        year: '2-digit', month: '2-digit', day: '2-digit',
                    },
                },
            },

            // Split public browser API key
            splitSdkApiKeyBrowser: process.env.SPLIT_SDK_API_KEY_BROWSER,
        };

        runtimeConfig = merge(runtimeConfig, appConfig);

    // Client side - Load from the window property
    } else if (process.env.VUE_ENV === 'client') {
        try {
            // eslint-disable-next-line prefer-destructuring
            runtimeConfig = window.nuu.runtimeConfig;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('[ERROR] runtimeConfig not available client side');
            runtimeConfig = {};
        }
    }

    return runtimeConfig;
}

module.exports = getRuntimeConfig;
