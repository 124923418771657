export const PRODUCT_FILTER_TYPES = {
    AVAILABILITY: 'availability_toggle',
    SORT: 'sort',
    FILTER: 'filter',
};

export const SEARCH_INDEX_TYPES = {
    SELLERS: 'sellers',
    PRODUCTS: 'products',
};

export const SELECT_CONTENT_TYPES = {
    CONTENT: 'content',
    PRODUCT_TRAY_LINK: 'product_tray_link',
};

export const CHECKOUT_STEPS = {
    REVIEW: 'review',
    SHIPPING: 'shipping',
    PAYMENT: 'payment',
};

export const CONTENT_CARD_IDS = {
    GLOBAL_PENCIL_BANNER: 'global_pencil_banner',
};

export const PAGINATION_SCROLL_TYPES = {
    AUTO: 'auto',
    MANUAL: 'manual',
};

export const NOTIFICATION_TYPES = {
    stickyNotification: 'sticky-notification',
    modal: 'modal',
    box: 'box',
};

export const SELECT_LINK_TYPES = {
    FOOTER: 'footer',
};

export const ANONYMOUS_EMAIL_SUBSCRIBE_SLUG = 'anonymous-email-subscribe';

export const MARKETING_SMS_SUBSCRIBE_SLUG = 'rent-marketing-sms-subscribe';

export const NOTIFICATION_DISMISS = 'dismiss';

export const TOP_NAV_EXPAND = 'top_nav_expand';
