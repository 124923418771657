import { defineAsyncComponent, markRaw } from 'vue';

export const MODAL_STYLE_TYPES = [
    'LEFT',
    'RIGHT',
    'BOTTOM',
    'BOTTOM-EXTRA-SHORT',
    'BOTTOM-SHORT',
    'BOTTOM-FULLSCREEN',
    'FULLSCREEN',
    'CENTER',
    'TOP',
];
// Whitelisted query params which can be passed to a modal's props when launching via a hash-event
// should start with 'modal-' what comes after will be the prop name
// if the prop is not defined on the modal component, it will be ignored
// ex: ?modal-skuId=abc will populate a modals skuId prop as abc
export const GLOBAL_MODAL_QUERY_PROPS = {
    exampleModalProp: 'modal-example-modal-prop',
    initialServiceErrorMessage: 'modal-initialServiceErrorMessage',
    skuId: 'modal-skuId',
};

export const MINIMUM_LOAD_TIME = 750;

export const GLOBAL_DISMISS_MODALS = {
    dismissModal: {
        id: 'dismiss-modal',
    },
};

/*
A modal object can have the following properties:
    - id (required): modal's unique identifier
    - path (required): path intended to be sent to GA pageview. Can be either absolute or relative.
        If the path is relative (does not start with /), the pageview sent to GA will use the path provided appended
        to the end of the current route that the user is on. For example, the editEmail modal's pageview
        will be /account/profile/edit-email.
        If the path is absolute, the pageview sent to GA will only be the path provided. For example, the boxHome
        modal's pageview will be /box.
    - analytics (optional): the route's analytics object used to populate the pageCategory and pageType dimensions
        If this object is populated, it will set the pageCategory and pageType dimensions for the pageview event as
        well as an events that occur in the modal.
        If this object is not populated, the pageCategory and pageType dimensions will inherit the route's pageCategory
        and pageType provided.
        Typically, the analytics object is only populated if the path is absolute.
*/
export const GLOBAL_MODALS = {
    exampleModal: {
        id: 'example-modal',
        path: '/example-modal',
        analytics: {
            pageCategory: 'example-modal',
            pageType: 'example-modal',
        },
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ExampleModal" */
                '~coreModules/modals/components/ExampleModal.vue'))),
        title: 'modals__example-modal-title',
        styleType: 'BOTTOM',
        isLight: false,
        isFullBleedContent: false,
    },
    joinModal: {
        id: 'join-modal',
        path: '/join-modal',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "joinModal" */
                '~coreRoutes/join/components/JoinModal.vue'))),
        isFullBleedContent: true,
        isContentCentered: true,
        isLight: false,
        styleType: 'BOTTOM',
        title: 'login__unauthModal--modalTitle',
    },
    pdpImageViewerZoom: {
        id: 'product-image-viewer-zoom-modal',
        path: '/image-zoom',
        analytics: {
            pageCategory: 'product',
            pageType: 'product',
        },
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "ImageViewerZoom" */
                '~coreRoutes/product/components/ImageViewerZoom.vue'))),
        styleType: 'FULLSCREEN',
        isLight: false,
        isFullBleedContent: true,
        focusFirstElOnLoad: false,
    },
    navMenuList: {
        id: 'navigation-menu-list',
        path: '/navigation',
        analytics: {
            pageCategory: 'navigation',
            pageType: 'navigation',
        },
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "NavigationMenuL2" */
                '~coreModules/nav/components/NavigationMenuList.vue'))),
        isFullBleedContent: true,
        isLight: false,
        styleType: 'LEFT',
    },
    anonymousEmailSubscribe: {
        id: 'email-subscribe',
        path: '/email-subscribe',
        analytics: {
            pageCategory: 'in-app-campaign',
            pageType: 'in-app-campaign',
        },
        title: 'emailCapture__modal--title',
        styleType: 'BOTTOM',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "AnonymousEmailSubscribe" */
                '~coreModules/core/components/ui/AnonymousEmailSubscribe.vue'))),
        props: {
            isModal: true,
        },
        isLight: false,
        isFullBleedContent: true,
    },
    baseMenuModal: {
        id: 'base-menu-modal',
        path: '/base-menu-modal',
        styleType: 'BOTTOM-SHORT',
        component: markRaw(
            defineAsyncComponent(() => import(
                /* webpackChunkName: "BaseMenuModal" */
                '~coreModules/menu/components/BaseMenuModal.vue'))),
        isLight: false,
        isFullBleedContent: true,
        hasInnerPadding: false,
    },
    ...GLOBAL_DISMISS_MODALS,
};

/* Modals which can be triggered via a hash in the URL */
/* add title to the GLOBAL_REAL_TIME_SLUGS */

// eslint-disable-next-line no-unused-vars
export function getGlobalModalHashEvents(featureConfig) {
    return {
        [`#${GLOBAL_MODALS.anonymousEmailSubscribe.id}`]: GLOBAL_MODALS.anonymousEmailSubscribe,
        [`#${GLOBAL_MODALS.dismissModal.id}`]: GLOBAL_MODALS.dismissModal,
        [`#${GLOBAL_MODALS.joinModal.id}`]: GLOBAL_MODALS.joinModal,
    };
}

export const MODAL_TRANSITION_DURATION = 400;
