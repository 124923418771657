import {
    nuColorPrimary,
    nuColorSecondary,
    nuColorBlack,
    nuColorWhite,
    nuColorGray,
} from '~coreModules/core/js/temp-colors';

export const MAX_SHOPPABLE_IMAGE_DRAWER_PRODUCTS = 16;
export const MAX_CONTENTFUL_ROW_COLUMN_OFFSET = 8;
export const DEFAULT_IMAGE_QUALITY = 90;

// the list of custom modules which map directly onto a particular component
export const CONTENTFUL_CUSTOM_MODULE_TYPES = {
    BRANDS_ROW: 'custom--brandsRow',
    VIDEO_ROW: 'custom--videoRow',
    FIVE_UP_GRID_MODULE: 'custom--fiveUp',
    PRIVACY_OPT_OUT: 'custom--privacyOptOut',
    TWO_UP_ROW: 'custom--twoUpRow',
    FERRIS_WHEEL_ROW: 'custom--ferrisWheel',
    BANNER_ROW: 'custom--bannerRow',
    PLAN_SUMMARY_ROW: 'custom--planSummary',
    APP_ROW: 'custom--appRow',
    ILLUSTRATED_LIST_ITEM: 'custom--illustratedListItem',
    PLAN_PILL_ROW: 'custom--planPill',
};

export const CONTENTFUL_MODULE_TYPES = {
    CAROUSEL: 'slider',
    IMAGE: 'image',
    PRODUCT_TRAY: 'productTray',
    TABLE: 'table',
    EDITORIAL_TEXT: 'editorialText',
    SOCIAL_CAROUSEL: 'socialCarousel',
    SOCIAL_GRID: 'socialGrid',
    SHOPPABLE_IMAGE: 'shoppableImage',
    ACCORDION_GROUP: 'accordionGroup',
    CARD: 'card',
    HERO_VIDEO: 'heroVideo',
    PEEKING_CAROUSEL: 'peekingSlider',
    ...CONTENTFUL_CUSTOM_MODULE_TYPES,
};

export const CONDITIONAL_CONTENTFUL_MODULE_TYPES = [
    CONTENTFUL_MODULE_TYPES.PRODUCT_TRAY,
    CONTENTFUL_MODULE_TYPES.SOCIAL_CAROUSEL,
    CONTENTFUL_MODULE_TYPES.SOCIAL_GRID,
];

export const EXCLUDED_CONTENTFUL_CONTENT_CLICKED_TYPES = [
    CONTENTFUL_MODULE_TYPES.PRODUCT_TRAY,
    CONTENTFUL_MODULE_TYPES.CAROUSEL,
    CONTENTFUL_MODULE_TYPES.SOCIAL_CAROUSEL,
];

export const CONTENT_TYPES = {
    BROWSE_PAGE: 'browsePage',
    CONTENT_PAGE: 'contentPage',
    CONTENT_SLOT: 'contentSlot',
    CUSTOM_CONTENT_PAGE: 'customContentPage',
    HELP_PAGE: 'helpPage',
    NAV_CONTENT_CONTAINER: 'navigationContentContainer',
    REVIEWS_QUESTION_SET: 'reviewsQuestionSet',
    REVIEWS_SCHEME: 'reviewsScheme',
};

export const FILTERED_CONTENT_TYPES = [
    CONTENT_TYPES.BROWSE_PAGE,
    CONTENT_TYPES.CONTENT_PAGE,
];

export const CONTENT_SLUGS = {
    EMPTY_FILTERED_CATALOG_CONTENT_SLUG: 'no-filter-results',
    REPORT_PROBLEM: 'report-problem',
    NOT_FOUND_PAGE: 'not-found-page',
    SHOPPING: 'shopping',
    SELLING_SHIPPING: 'selling-shipping',
    ERROR_500_PAGE: 'error-500-page',
};

export const colorMap = new Map([
    ['black', nuColorBlack],
    ['primary', nuColorPrimary],
    ['secondary', nuColorSecondary],
    ['white', nuColorWhite],
    ['gray', nuColorGray],
]);

export const PRODUCT_TRAY_TYPES = {};

export const PRODUCT_TRAY_TRAY_STYLES = {
    largeWithTitle: 'large-with-title',
    smallNoTitle: 'small-no-title',
};

export const CARD_MODULE_LAYOUT_TYPES = {
    default: 'default',
    compactOnSmall: 'compactOnSmall',
    cardWithLinks: 'cardWithLinks',
};

export const EDITORIAL_TEXT_BODY_STYLES = {
    caption: 'caption',
};

export const EDITORIAL_TEXT_POSITIONS = {
    below: 'below',
    center: 'center',
    top: 'top',
    bottom: 'bottom',
};

export const GLOBAL_REAL_TIME_SLUGS = [
    'accountCreate__profile--username',
    'accountCreate__profile--errorUsernameMinCharacter',
    'accountCreate__landing--title',
    'accountCreate__landing--subtitle',
    'account__profilePhoto--formatError',
    'account__profilePhoto--sizeError',
    'account__email--label',
    'account__firstName--label',
    'account__lastName--label',
    'account__phone--label',
    'account__smsToken--label',
    'address__city--label',
    'address__error--lineOneRegex',
    'address__line1--label',
    'address__state--label',
    'address__zip--label',
    'emailCapture__footer--header',
    'emailCapture__footer--subheader',
    'emailCapture__footer--success',
    'emailCapture__privacyPolicyLink',
    'emailCapture__modal--title',
    'emailCapture__modal--header',
    'emailCapture__optInText',
    'emailCapture__modal--subheader',
    'emailCapture__modal--successHeader',
    'emailCapture__modal--successSubheader',
    'emailCapture__modal--joinNowCta',
    'global__and',
    'global__menu',
    'global__myAccount',
    'global__myBag',
    'global__nuulyThriftHome',
    'global__search',
    'global__username--placeholder',
    'header__browse--label',
    'header__signOut--label',
    'login__unauthModal--modalTitle',
    'meta__join--description',
    'meta__join--title',
    'onboarding__transition__profileCreateConfirmationHeader',
    'onboarding__transition__profileCreateConfirmationSubheader',
    'onboarding__transition__profileCreateConfirmationCta',
    'validationError__birthday--overage',
    'validationError__birthday--regex',
    'validationError__birthday--underage',
    'validationError__braSize--regex',
    'validationError__bust--regex',
    'validationError__date--inputRequired',
    'validationError__email--format-escaped',
    'validationError__name--format',
    'validationError__name--inputRequired',
    'validationError__pauseCycles--empty',
    'validationError__phone--format',
    'validationError__required--withLabel',
    'validationError__smsToken--format',
    'validationError__weight--regex',
];

export const PAGE_HEADER_TEXT_COLORS = {
    primary: 'primary',
    secondary: 'secondary',
    black: 'black',
    white: 'white',
};
